import { TCurrentModal } from "modals/registered-modals";

import { useDispatch, useSelector } from "react-redux";

import { setCurrentModal } from "store/features/modal";
import { selectModal } from "store/features/modal/";
import { IModal } from "store/features/modal/types";

type TModalNames = Required<TCurrentModal>;

export default function useModal(): {
    isOpen: (modalName?: TModalNames) => boolean;
    openModal: (name: TModalNames, props?: any) => void;
    onClose: () => void;
    currentModal: IModal | null;
} {
    const currentModal = useSelector(selectModal);
    const dispatch = useDispatch();

    const isOpen = (modalName?: TCurrentModal) =>
        currentModal?.name === modalName || false;

    const openModal = (name: TCurrentModal, props?: any) => {
        const modalData: IModal = { name, props };
        dispatch(setCurrentModal(modalData));
    };

    const onClose = () => {
        dispatch(setCurrentModal(null));
    };

    return {
        isOpen,
        openModal,
        onClose,
        currentModal,
    };
}
