import { apiSlice } from "../index";

export const resturantApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        addResturant: builder.mutation({
            query: (body) => ({
                url: "resturants",
                method: "POST",
                body,
            }),
            invalidatesTags: ["getRestaurants"],
        }),
        editResturant: builder.mutation({
            query: ({ _id, ...rest }) => {
                return {
                    url: `resturants/${_id}`,
                    method: "PUT",
                    body: rest,
                };
            },
            invalidatesTags: (result, error, arg) => {
                return [
                    { type: "getRestaurants" },
                    {
                        type: "getAllRestaurants",
                        page: arg?.page,
                        limit: arg?.limit,
                        name: arg?.name,
                        status: arg?.status,
                    },
                    {
                        type: "getResturant",
                        id: arg?._id,
                    },
                ];
            },
        }),
        getUserRestaurants: builder.query({
            query: () => ({
                url: "resturants/users-resturant",
                method: "GET",
            }),
            providesTags: ["getRestaurants"],
        }),
        getSingleResturant: builder.query({
            query: ({ id }) => ({
                url: `resturants/all/${id}`,
                method: "GET",
            }),
            providesTags: ["getResturant"],
        }),
        deleteResturant: builder.mutation({
            query: (id) => ({
                url: `resturants/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["getRestaurants"],
        }),
        getAllRestaurants: builder.query({
            query: ({
                page = 1,
                limit = 10,
                name = "",
                status = "",
            }: {
                page?: number;
                limit?: number;
                name?: string;
                status?: string;
            }) => {
                const url = ["resturants/all?"];
                if (page) {
                    url.push(`page=${page}&`);
                }

                if (limit) {
                    url.push(`limit=${limit}&`);
                }

                if (name) {
                    url.push(`name=${name}&`);
                }

                if (status) {
                    url.push(`status=${status}`);
                }

                return {
                    url: url.join(""),
                    method: "GET",
                };
            },
        }),
        updateResturantStatus: builder.mutation({
            query: ({ _id, ...rest }) => {
                return {
                    url: `resturants/change-status/${_id}`,
                    method: "PUT",
                    body: rest,
                };
            },
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        resturantApiSlice.endpoints.getSingleResturant.initiate(
                            {
                                id: arg?._id,
                            }
                        )
                    );
                } catch (error) {
                    console.log(error);
                }
            },
            invalidatesTags: (result, error, arg) => {
                return [
                    { type: "getRestaurants" },
                    {
                        type: "getAllRestaurants",
                        page: arg?.page,
                        limit: arg?.limit,
                        name: arg?.name,
                        status: arg?.status,
                    },
                    {
                        type: "getResturant",
                        id: arg?._id,
                    },
                ];
            },
        }),
    }),
});

export const {
    useAddResturantMutation,
    useGetUserRestaurantsQuery,
    useEditResturantMutation,
    useGetSingleResturantQuery,
    useDeleteResturantMutation,
    useGetAllRestaurantsQuery,
    useUpdateResturantStatusMutation,
} = resturantApiSlice;
