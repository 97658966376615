import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { selectAuth } from "store/features/auth";

export default function PrivateRoute() {
    const auth = useSelector(selectAuth);

    return auth.token ? <Outlet /> : <Navigate to="/" />;
}
