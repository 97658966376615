import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    // password: Yup.string()
    //     .min(6, "Password must be at least 6 characters")
    //     .required("Required"),
});

export const addAdminSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    name: Yup.string().required("Required"),
    role: Yup.string().required("Required"),
    // password: Yup.string()
    //     .min(6, "Password must be at least 6 characters")
    //     .required("Required"),
});
