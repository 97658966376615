import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

const initialState = {
    isSidebarOpen: false,
};

const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        toggleSidebar(state) {
            state.isSidebarOpen = !state.isSidebarOpen;
        },
    },
});

export const { toggleSidebar } = uiSlice.actions;
export const selectIsSidebarOpen = (state: RootState) => state.ui.isSidebarOpen;

const uiReducer = uiSlice.reducer;

export default uiReducer;
