import MySwal from "components/MySwal";
import { Button, Modal } from "flowbite-react";
import useModal from "hooks/useModal";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useVerifyOtpMutation } from "store/apis/otp";
import { selectAuth, updateValidation } from "store/features/auth";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

type InputRef = React.RefObject<HTMLInputElement>;

export default function InsertOtp({ isOpen, onClose }: Props) {
    const { openModal, currentModal } = useModal();
    const [verifyOtp, result] = useVerifyOtpMutation();
    const auth = useSelector(selectAuth);
    const otpToken = currentModal?.props?.otpToken;
    const otpType = currentModal?.props?.otpType;

    const otpValue = useRef<string>();
    const dispatch = useDispatch();

    const onSubmit = async (e: any) => {
        e.preventDefault();
        try {
            await verifyOtp({
                otp: otpValue.current,
                otpType,
                otpToken,
                email: auth.user?.email || currentModal?.props?.email,
            }).unwrap();
            if (otpType === "REGISTER") {
                dispatch(updateValidation(true));
                onClose();
            } else {
                openModal("ResetPassword", {
                    email: currentModal?.props?.email,
                    otpToken: otpToken,
                });
            }
        } catch (error: any) {
            MySwal.fire({
                icon: "error",
                title: "Oops...",
                text: error?.data?.message,
                confirmButtonColor: "#3085d6",
                customClass: {
                    confirmButton: `bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                            w-full sm:w-auto
                            `,
                    actions: "flex justify-center gap-2",
                },
            });
        }
    };

    return (
        <Modal show={isOpen} position="center" onClose={onClose}>
            <Modal.Header>Enter otp</Modal.Header>
            <Modal.Body>
                <h2 className="tex-xl lg:text-2xl font-bold mb-4 text-center">
                    Enter the 6-digit code we sent to your email
                </h2>
                <form className="flex flex-col gap-4" onSubmit={onSubmit}>
                    <input
                        id="otp"
                        type="text"
                        placeholder="Enter otp"
                        required={true}
                        maxLength={6}
                        minLength={6}
                        name="otp"
                        onChange={(e) => (otpValue.current = e.target.value)}
                        className="text-center py-4 font-bold !text-xl lg:!text-2xl border-0 !outline-transparent !ring-0 border-b-2 border-b-black"
                    />

                    <Button className="mt-4" type="submit">
                        Submit
                    </Button>
                </form>
            </Modal.Body>
        </Modal>
    );
}
