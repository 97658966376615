import Loader from "components/Loader";
import MySwal from "components/MySwal";
import { Button, Modal } from "flowbite-react";
import useModal from "hooks/useModal";
import { useNavigate } from "react-router";

import { useDeleteResturantMutation } from "store/apis/resturant";
import { IRestuantsTable } from "typedef/interface";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export default function DeleteResturant({ isOpen, onClose }: Props) {
    const { currentModal } = useModal();

    const row: IRestuantsTable = currentModal?.props;

    const [deleteResturant, deleteResult] = useDeleteResturantMutation();

    return (
        <Modal show={isOpen} position="center" onClose={onClose}>
            <Modal.Header>{row?.title}</Modal.Header>
            <Modal.Body className="flex items-center flex-col gap-10">
                <h2 className="tex-xl lg:text-2xl font-bold mb-4 text-center">
                    Do you want to delete your restaurant details ?
                </h2>

                <div className="flex flex-row w-full">
                    <Button
                        onClick={async () => {
                            try {
                                const res2 = await deleteResturant(
                                    row?._id
                                )?.unwrap();
                                MySwal.fire({
                                    icon: "success",
                                    title: "Success",
                                    text: res2.message,
                                });

                                onClose?.();
                            } catch (error: any) {
                                MySwal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: error?.data?.message,
                                    confirmButtonColor: "#3085d6",
                                    customClass: {
                                        confirmButton: `bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                                w-full sm:w-auto
                                `,
                                        actions: "flex justify-center gap-2",
                                    },
                                });
                            }
                        }}
                        className="mt-4 w-full bg-red-400"
                    >
                        Delete
                    </Button>
                </div>
            </Modal.Body>
            <Loader isLoading={deleteResult.isLoading} />
        </Modal>
    );
}
