import { createSlice } from "@reduxjs/toolkit";
import { IModal, IModalState } from "./types";

const initialState: IModalState = {
    currentModal: null,
};

const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        setCurrentModal(
            state,
            action: {
                payload: IModal | null;
            }
        ) {
            state.currentModal = action.payload;
        },
    },
});

export const { setCurrentModal } = modalSlice.actions;

export const selectModal = (state: { modal: IModalState }) =>
    state.modal.currentModal;

const modalReducer = modalSlice.reducer;

export default modalReducer;
