import Loader from "components/Loader";
import MySwal from "components/MySwal";
import { Button, Checkbox, Label, Modal, TextInput } from "flowbite-react";
import { useFormik } from "formik";
import useModal from "hooks/useModal";
import React from "react";
import { useResetPasswordMutation } from "store/apis/auth";
import { resetPasswordSchema } from "validators/resetPasswordSchema";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

function Form() {
    const { onClose, currentModal } = useModal();

    const [showPassword, setShowPassword] = React.useState(false);

    const [resetPasswordFunc, resetPasswordResult] = useResetPasswordMutation();

    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        onSubmit: async (values) => {
            try {
                const res = await resetPasswordFunc({
                    otpToken: currentModal?.props?.otpToken,
                    email: currentModal?.props?.email,
                    password: values.password,
                    confirmPassword: values.confirmPassword,
                }).unwrap();

                onClose();

                MySwal.fire({
                    icon: "success",
                    title: "Success",
                    text:
                        res?.data?.message ||
                        "Password has been reset successfully",
                    confirmButtonColor: "#3085d6",
                    customClass: {
                        confirmButton: `bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                                w-full sm:w-auto
                                `,
                        actions: "flex justify-center gap-2",
                    },
                });
            } catch (error: any) {
                MySwal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error?.data?.message,
                    confirmButtonColor: "#3085d6",
                    customClass: {
                        confirmButton: `bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                                w-full sm:w-auto
                                `,
                        actions: "flex justify-center gap-2",
                    },
                });
            }
        },
        validationSchema: resetPasswordSchema,
    });

    return (
        <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="password1" value="Enter new password" />
                </div>
                <TextInput
                    id="password1"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password"
                    required={true}
                    helperText={formik.errors.password ?? ""}
                    {...formik.getFieldProps("password")}
                />
            </div>

            <div>
                <div className="mb-2 block">
                    <Label htmlFor="password1" value="Confirm new password" />
                </div>
                <TextInput
                    id="password1"
                    type={showPassword ? "text" : "password"}
                    placeholder="Confirm password"
                    helperText={formik.errors.confirmPassword ?? ""}
                    required={true}
                    {...formik.getFieldProps("confirmPassword")}
                />
            </div>

            <div className="flex items-center gap-2">
                <Checkbox
                    id="show-passwors"
                    checked={showPassword}
                    onChange={() => setShowPassword(!showPassword)}
                />
                <Label htmlFor="show-passwors">Show password</Label>
            </div>

            <Button className="mt-4" type="submit">
                Submit
            </Button>
            <Loader isLoading={resetPasswordResult.isLoading} />
        </form>
    );
}
export default function ResetPassword({ isOpen, onClose }: Props) {
    return (
        <Modal show={isOpen} position="center" onClose={onClose}>
            <Modal.Header>Reset password</Modal.Header>
            <Modal.Body>
                <Form />
            </Modal.Body>
        </Modal>
    );
}
