import React, { useEffect } from "react";

interface IUseWindowSize {
    windowSize: number | undefined;
    isGreaterThan: (size: TScreens) => boolean;
    isLessThan: (size: TScreens) => boolean;
    isBetween: (min: TScreens, max: TScreens) => boolean;
    isBetweenOrEqual: (min: TScreens, max: TScreens) => boolean;
    isGreaterThanEqual: (size: TScreens) => boolean;
    isLessThanEqual: (size: TScreens) => boolean;
    isEqual: (size: TScreens) => boolean;
}

const screenSizes = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    "2xl": 1536,
};

export type TScreens = "sm" | "md" | "lg" | "xl" | "2xl";

export default function useWindow(): IUseWindowSize {
    const [windowSize, setWindowSize] = React.useState<number>(0);

    useEffect(() => {
        if (window) {
            setWindowSize(window.innerWidth);

            const handleResize = () => {
                setWindowSize(window.innerWidth);
            };

            window.addEventListener("resize", handleResize);

            return () => {
                window.removeEventListener("resize", handleResize);
            };
        }
    }, []);

    const isGreaterThan = (size: TScreens): boolean =>
        windowSize > screenSizes[size];

    const isLessThan = (size: TScreens): boolean =>
        windowSize < screenSizes[size];

    const isBetween = (min: TScreens, max: TScreens): boolean =>
        windowSize > screenSizes[min] && windowSize < screenSizes[max];

    const isBetweenOrEqual = (min: TScreens, max: TScreens): boolean =>
        windowSize >= screenSizes[min] && windowSize <= screenSizes[max];

    const isGreaterThanEqual = (size: TScreens): boolean =>
        windowSize >= screenSizes[size];

    const isLessThanEqual = (size: TScreens): boolean =>
        windowSize <= screenSizes[size];

    const isEqual = (size: TScreens): boolean =>
        windowSize === screenSizes[size];

    return {
        windowSize,
        isGreaterThan,
        isLessThan,
        isBetween,
        isBetweenOrEqual,
        isGreaterThanEqual,
        isLessThanEqual,
        isEqual,
    };
}
