import Loader from "components/Loader";
import MySwal from "components/MySwal";
import { Button, Label, Modal, Select, TextInput } from "flowbite-react";
import { useFormik } from "formik";
import useModal from "hooks/useModal";
import { useAddAdminMutation } from "store/apis/auth";
import { addAdminSchema } from "validators/loginSchema";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

function Form() {
    const { onClose } = useModal();

    const [addPaswordFn, addPaswordResult] = useAddAdminMutation();

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            role: "admin",
        },
        onSubmit: async (values) => {
            try {
                const res = await addPaswordFn(values).unwrap();

                onClose();

                MySwal.fire({
                    icon: "success",
                    title: "Success",
                    text:
                        res?.data?.message ||
                        "Admin added successfully. Please check your email for password.",
                    confirmButtonColor: "#3085d6",
                    customClass: {
                        confirmButton: `bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                                w-full sm:w-auto
                                `,
                        actions: "flex justify-center gap-2",
                    },
                });
            } catch (error: any) {
                console.log(error);
                MySwal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error?.data?.message,
                    confirmButtonColor: "#3085d6",
                    customClass: {
                        confirmButton: `bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                                w-full sm:w-auto
                                `,
                        actions: "flex justify-center gap-2",
                    },
                });
            }
        },
        validationSchema: addAdminSchema,
    });

    return (
        <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="email" value="Email" />
                </div>
                <TextInput
                    id="email"
                    placeholder="Enter email"
                    required={true}
                    helperText={formik.errors.email ?? ""}
                    {...formik.getFieldProps("email")}
                />
            </div>

            <div>
                <div className="mb-2 block">
                    <Label htmlFor="name" value="Name" />
                </div>
                <TextInput
                    id="name"
                    placeholder="Enter name"
                    helperText={formik.errors.name ?? ""}
                    required={true}
                    {...formik.getFieldProps("name")}
                />
            </div>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="role" value="Select role" />
                </div>
                <Select
                    id="role"
                    required={true}
                    {...formik.getFieldProps("role")}
                >
                    <option value="admin">Admin</option>
                    <option value="superadmin">Super Admin</option>
                </Select>
            </div>

            <Button className="mt-4" type="submit">
                Submit
            </Button>
            <Loader isLoading={addPaswordResult.isLoading} />
        </form>
    );
}
export default function AddAdmin({ isOpen, onClose }: Props) {
    return (
        <Modal show={isOpen} position="center" onClose={onClose}>
            <Modal.Header>Add Admin</Modal.Header>
            <Modal.Body>
                <Form />
            </Modal.Body>
        </Modal>
    );
}
