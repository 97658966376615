import { useSelector } from "react-redux";
import { selectModal } from "store/features/modal";
import registeredModals from "./registered-modals";
import useModal from "hooks/useModal";

export default function RenderModal() {
    const currentModal = useSelector(selectModal);
    const { onClose } = useModal();

    if (!currentModal) return null;

    const modalName = currentModal.name;

    const Modal = registeredModals[modalName as keyof typeof registeredModals];

    return <Modal isOpen onClose={onClose} {...currentModal?.props} />;
}
