import Loader from "components/Loader";
import MySwal from "components/MySwal";
import { Button, Modal } from "flowbite-react";
import useModal from "hooks/useModal";
import { useSelector } from "react-redux";
import { useSendOtpMutation } from "store/apis/otp";
import { selectAuth } from "store/features/auth";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export default function OtpWarring({ isOpen, onClose }: Props) {
    const { openModal } = useModal();
    const [sendOtp, otpResult] = useSendOtpMutation();
    const auth = useSelector(selectAuth);

    const sendOtpHandler = async () => {
        try {
            const otpResult = await sendOtp({
                email: auth.user?.email as string,
                otpType: "REGISTER",
            }).unwrap();

            openModal("InsertOtp", {
                otpToken: otpResult?.data?.otpToken,
                otpType: "REGISTER",
            });
        } catch (error: any) {
            MySwal.fire({
                icon: "error",
                title: "Oops...",
                text: error?.data?.message,
                confirmButtonColor: "#3085d6",
                customClass: {
                    confirmButton: `bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                            w-full sm:w-auto
                            `,
                    actions: "flex justify-center gap-2",
                },
            });
        }
    };

    return (
        <Modal show={isOpen} position="center" onClose={onClose}>
            <Modal.Header>Verify your account</Modal.Header>
            <Modal.Body className="flex items-center flex-col gap-10">
                <h2 className="tex-xl lg:text-2xl font-bold mb-4 text-center">
                    Verify your account with your email
                </h2>

                <Button
                    className="mt-4 w-full"
                    type="submit"
                    onClick={sendOtpHandler}
                >
                    Start verification process
                </Button>
            </Modal.Body>
            <Loader isLoading={otpResult.isLoading} />
        </Modal>
    );
}
