import Loader from "components/Loader";
import Layout from "layout/Layout";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AdminRoute from "route/AdminRoute";
import AuthPrivateRoute from "route/AuthRoutes";
import PrivateRoute from "route/PrivateRoute";

import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

const MyAccount = React.lazy(() => import("screens/MyAccount"));
const RegisterScreen = React.lazy(() => import("screens/RegisterScreen"));
const RegisteredUsers = React.lazy(() => import("screens/RegisteredUsers"));
const Restaurants = React.lazy(() => import("screens/Restaurants"));
const UserDashboard = React.lazy(() => import("screens/UserDashboard"));
const AddResturant = React.lazy(() => import("screens/AddResturant"));
const ManageAdmins = React.lazy(() => import("screens/ManageAdmins"));
const LoginScreen = React.lazy(() => import("screens/LoginScreen"));
const EditResturant = React.lazy(() => import("screens/EditResturant"));


export default function App() {
    return (
        <Layout>
            <Suspense fallback={<Loader isLoading />}>
                <Routes>
                    <Route path="/" element={<AuthPrivateRoute />}>
                        <Route path="/" element={<LoginScreen />} />
                        <Route path="/register" element={<RegisterScreen />} />
                    </Route>
                    <Route path="/*" element={<PrivateRoute />}>
                        <Route path="dashboard" element={<UserDashboard />} />
                        <Route
                            path="add-restaurants"
                            element={<AddResturant />}
                        />
                        <Route path="my-account" element={<MyAccount />} />
                        <Route
                            path="edit-restaurants/:id"
                            element={<EditResturant />}
                        />
                        <Route path="admin/*" element={<AdminRoute />}>
                            <Route
                                path="all-restaurants"
                                element={<Restaurants />}
                            />
                            <Route path="users" element={<RegisteredUsers />} />
                            <Route path="admins" element={<ManageAdmins />} />
                        </Route>
                    </Route>
                </Routes>
            </Suspense>
        </Layout>
    );
}
