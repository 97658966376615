import Loader from "components/Loader";
import MySwal from "components/MySwal";
import { Button, Checkbox, Label, Modal, TextInput } from "flowbite-react";
import { useFormik } from "formik";
import useModal from "hooks/useModal";
import React from "react";
import { useChangePasswordMutation } from "store/apis/auth";
import { changePasswordSchema } from "validators/resetPasswordSchema";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

function Form() {
    const { onClose } = useModal();

    const [showPassword, setShowPassword] = React.useState(false);

    const [changePasswordFunc, changePasswordResult] =
        useChangePasswordMutation();

    const formik = useFormik({
        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        onSubmit: async (values) => {
            try {
                const res = await changePasswordFunc({
                    oldPassword: values.oldPassword,
                    newPassword: values.newPassword,
                    confirmPassword: values.confirmPassword,
                }).unwrap();

                onClose();

                MySwal.fire({
                    icon: "success",
                    title: "Success",
                    text:
                        res?.data?.message ||
                        "Password has been reset successfully",
                    confirmButtonColor: "#3085d6",
                    customClass: {
                        confirmButton: `bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                                w-full sm:w-auto
                                `,
                        actions: "flex justify-center gap-2",
                    },
                });
            } catch (error: any) {
                MySwal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error?.data?.message,
                    confirmButtonColor: "#3085d6",
                    customClass: {
                        confirmButton: `bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                                w-full sm:w-auto
                                `,
                        actions: "flex justify-center gap-2",
                    },
                });
            }
        },
        validationSchema: changePasswordSchema,
    });

    return (
        <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="password1" value="Old password" />
                </div>
                <TextInput
                    id="password1"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter old password"
                    helperText={formik.errors.oldPassword ?? ""}
                    required={true}
                    {...formik.getFieldProps("oldPassword")}
                />
            </div>

            <div>
                <div className="mb-2 block">
                    <Label htmlFor="password2" value="New password" />
                </div>
                <TextInput
                    id="password2"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter new password"
                    helperText={formik.errors.newPassword ?? ""}
                    required={true}
                    {...formik.getFieldProps("newPassword")}
                />
            </div>

            <div>
                <div className="mb-2 block">
                    <Label htmlFor="password3" value="Confirm new password" />
                </div>
                <TextInput
                    id="password3"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password again"
                    helperText={formik.errors.confirmPassword ?? ""}
                    required={true}
                    {...formik.getFieldProps("confirmPassword")}
                />
            </div>

            <div className="flex items-center gap-2">
                <Checkbox
                    id="show-password"
                    checked={showPassword}
                    onChange={() => setShowPassword(!showPassword)}
                />
                <Label htmlFor="show-password">Show password</Label>
            </div>

            <Button className="mt-4" type="submit">
                Submit
            </Button>
            <Loader isLoading={changePasswordResult.isLoading} />
        </form>
    );
}
export default function ChangePassword({ isOpen, onClose }: Props) {
    return (
        <Modal show={isOpen} position="center" onClose={onClose}>
            <Modal.Header>Change password</Modal.Header>
            <Modal.Body>
                <Form />
            </Modal.Body>
        </Modal>
    );
}
