import MySwal from "components/MySwal";
import { login, logout, updateToken } from "store/features/auth";
import { setCurrentModal } from "store/features/modal";
import { apiSlice } from "../index";
import { IForgotPassBody } from "./types";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (body) => ({
                url: "auth/login",
                method: "POST",
                body,
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data: result } = (await queryFulfilled) as any;

                    if (result.data) {
                        dispatch(
                            login({
                                token: result.data.accessToken,
                                user: result.data.user,
                                refreshToken: result.data.refreshToken,
                            })
                        );
                        if (
                            !result.data.user.hasVerifiedEmail &&
                            result.data.user.role !== "admin" &&
                            result.data.user.role !== "superadmin"
                        ) {
                            dispatch(
                                setCurrentModal({
                                    name: "OtpWarring",
                                })
                            );
                        }
                    }

                    return result;
                } catch (error: any) {
                    MySwal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: error?.data?.message,
                        confirmButtonColor: "#3085d6",
                        customClass: {
                            confirmButton: `bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                                    w-full sm:w-auto
                                    `,
                            actions: "flex justify-center gap-2",
                        },
                    });
                }
            },
            invalidatesTags: ["getRestaurants"],
        }),
        register: builder.mutation({
            query: (body) => ({
                url: "auth/register",
                method: "POST",
                body,
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data: result } = (await queryFulfilled) as any;

                    if (result.data) {
                        dispatch(
                            login({
                                token: result.data.accessToken,
                                user: result.data.user,
                                refreshToken: result.data.refreshToken,
                            })
                        );
                        dispatch(
                            setCurrentModal({
                                name: "OtpWarring",
                            })
                        );
                    }

                    // if (result.data) {
                    //     const sendOtpResult = await dispatch(
                    //         otpApiSlice.endpoints.sendOtp.initiate({
                    //             email: args.email,
                    //             otpType: "REGISTER",
                    //         })
                    //     ).unwrap();

                    //     if (sendOtpResult.data) {
                    //         dispatch(
                    //             setCurrentModal({
                    //                 name: "InsertOtp",
                    //                 props: {
                    //                     email: sendOtpResult.data.email,
                    //                     otpType: "REGISTER",
                    //                     otpToken: sendOtpResult.data.otpToken,
                    //                 },
                    //             })
                    //         );

                    //         localStorage.setItem(
                    //             "otpToken",
                    //             sendOtpResult.data.otpToken
                    //         );
                    //     }
                    // }

                    return result;
                } catch (err: any) {
                    MySwal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: err?.data?.message,
                        confirmButtonColor: "#3085d6",
                        customClass: {
                            confirmButton: `bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                                w-full sm:w-auto
                                `,
                            actions: "flex justify-center gap-2",
                        },
                    });
                }
            },
            invalidatesTags: ["getRestaurants"],
        }),
        refreshToken: builder.mutation({
            query: (body) => ({
                url: "auth/refresh",
                method: "POST",
                body,
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data: result } = (await queryFulfilled) as any;

                    if (result.data) {
                        dispatch(
                            updateToken({
                                token: result?.data?.accessToken,
                                refreshToken: result?.data?.refreshToken,
                            })
                        );
                    }
                } catch (error) {
                    dispatch(logout());
                }
            },
        }),
        logout: builder.mutation({
            query: () => ({
                url: "auth/logout",
                method: "POST",
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const result = (await queryFulfilled) as any;

                    if (result) {
                        dispatch(logout());
                        localStorage.clear();
                    }

                    return result;
                } catch (error: any) {
                    MySwal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: error?.data?.message,
                        confirmButtonColor: "#3085d6",
                        customClass: {
                            confirmButton: `bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                                    w-full sm:w-auto
                                    `,
                            actions: "flex justify-center gap-2",
                        },
                    });
                }
            },
            invalidatesTags: ["getRestaurants"],
        }),
        resetPassword: builder.mutation({
            query: (body: IForgotPassBody) => ({
                url: "auth/reset-password",
                method: "POST",
                body,
            }),
        }),
        changePassword: builder.mutation({
            query: (body: {
                oldPassword: string;
                newPassword: string;
                confirmPassword: string;
            }) => ({
                url: "auth/change-password",
                method: "POST",
                body,
            }),
        }),
        getRegisteredUsers: builder.query({
            query: () => ({
                url: "auth/users",
            }),
        }),
        updateProfile: builder.mutation({
            query: (body: { name: string }) => ({
                url: "auth/update-profile",
                method: "PUT",
                body,
            }),
        }),
        addAdmin: builder.mutation({
            query: (body: { email: string; name: string }) => ({
                url: "auth/add-admin",
                method: "POST",
                body: {
                    ...body,
                    password: Math.random().toString(36).slice(-8),
                },
            }),
            invalidatesTags: ["getAdmins"],
        }),
        getAdmins: builder.query({
            query: () => ({
                url: "auth/admins",
            }),
            providesTags: ["getAdmins"],
        }),
        deleteAdmin: builder.mutation({
            query: (body: { id: string }) => {
                const url = `auth/delete-admin?id=${body.id}`;

                return {
                    url: url,
                    method: "DELETE",
                    body,
                };
            },
            invalidatesTags: ["getAdmins"],
        }),
    }),
});

export const {
    useLoginMutation,
    useRegisterMutation,
    useRefreshTokenMutation,
    useLogoutMutation,
    useResetPasswordMutation,
    useChangePasswordMutation,
    useGetRegisteredUsersQuery,
    useUpdateProfileMutation,
    useAddAdminMutation,
    useGetAdminsQuery,
    useDeleteAdminMutation,
} = authApiSlice;
