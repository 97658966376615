import Loader from "components/Loader";
import MySwal from "components/MySwal";
import { Button, Modal } from "flowbite-react";
import useModal from "hooks/useModal";
import { useDeleteAdminMutation } from "store/apis/auth";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export default function DeleteAdminWarning({ isOpen, onClose }: Props) {
    const [deleteAdminFn, deleteAdminResult] = useDeleteAdminMutation();

    const { currentModal } = useModal();

    const deleteAdmin = async () => {
        try {
            await deleteAdminFn({
                id: currentModal?.props?._id,
            }).unwrap();
            onClose();
            MySwal.fire({
                icon: "success",
                title: "Success",
                text: "Admin deleted successfully.",
                confirmButtonColor: "#3085d6",
                customClass: {
                    confirmButton: `bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                            w-full sm:w-auto
                            `,
                    actions: "flex justify-center gap-2",
                },
            });
        } catch (error: any) {
            MySwal.fire({
                icon: "error",
                title: "Oops...",
                text: error?.data?.message,
                confirmButtonColor: "#3085d6",
                customClass: {
                    confirmButton: `bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                            w-full sm:w-auto
                            `,
                    actions: "flex justify-center gap-2",
                },
            });
        }
    };

    return (
        <Modal show={isOpen} position="center" onClose={onClose}>
            <Modal.Header>Delete admin</Modal.Header>
            <Modal.Body className="flex items-center flex-col gap-10">
                <h2 className="tex-xl lg:text-2xl font-bold mb-4 text-center">
                    Are you sure you want to delete this admin?
                </h2>

                <div className="grid gap-4 grid-cols-2">
                    <Button
                        className="bg-red-500 hover:bg-red-600 text-white"
                        onClick={deleteAdmin}
                    >
                        Delete
                    </Button>
                    <Button className="" onClick={onClose}>
                        Cancel
                    </Button>
                </div>
            </Modal.Body>
            <Loader isLoading={deleteAdminResult.isLoading} />
        </Modal>
    );
}
