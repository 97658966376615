import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { selectAuth } from "store/features/auth";

export default function AdminRoute() {
    const auth = useSelector(selectAuth);
    const role = auth.user?.role || "";
    const roles = ["admin", "superadmin"];

    return roles.includes(role) ? <Outlet /> : <Navigate to="/" />;
}
