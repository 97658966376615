import { Navbar } from "flowbite-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectAuth } from "store/features/auth";

import { Fade as Hamburger } from "hamburger-react";
import { selectIsSidebarOpen, toggleSidebar } from "store/features/ui";

interface Props {}

function Header(props: Props, ref: React.RefObject<HTMLDivElement>) {
    const auth = useSelector(selectAuth);
    const isSidebarOpen = useSelector(selectIsSidebarOpen);
    const dispatch = useDispatch();

    return (
        <section
            className="fixed top-0 left-0 right-0 z-[10] shadow-md bg-white"
            ref={ref}
        >
            <div className="container py-2">
                <Navbar fluid={true}>
                    <Link
                        to={"/"}
                        className="flex justify-between items-center gap-1"
                    >
                        <img
                            src="/adaptive-icon.png"
                            className="h-6 sm:h-8 md:h-12"
                            alt="Flowbite Logo"
                        />
                        <span
                            className="self-center max-w-[10ch]  md:max-w-none
                     text-xs   sm:text-sm md:text-lg xl:text-xl font-semibold dark:text-white"
                        >
                            Get Today’s Specials
                        </span>
                    </Link>
                    {auth.user && (
                        <div className="flex gap-2 items-center">
                            <div className="">
                                <h2 className="text-sm sm:text-base md:text-lg xl:text-xl font-semibold">
                                    {auth.user?.name?.split(" ")[0] ?? "Guest"}
                                </h2>
                            </div>
                            <div className="md:hidden">
                                <Hamburger
                                    toggled={isSidebarOpen}
                                    toggle={() => dispatch(toggleSidebar())}
                                    size={20}
                                />
                            </div>
                        </div>
                    )}
                </Navbar>
            </div>
        </section>
    );
}

export default React.forwardRef(Header as any);
