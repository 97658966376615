import React from "react";
import Header from "./Header";
import RenderModal from "modals";
import SideNav from "./SideNav";
import { useSelector } from "react-redux";
import { selectAuth } from "store/features/auth";
import useWindow from "hooks/useWindow";

export default function Layout(props: React.PropsWithChildren<{}>) {
    const headerRef = React.useRef<HTMLDivElement>(null);
    const sidebarRef = React.useRef<HTMLDivElement>(null);
    const mainContainerRef = React.useRef<HTMLDivElement>(null);
    const { isGreaterThan, windowSize } = useWindow();
    const [isOpen, setIsOpen] = React.useState(false);

    const auth = useSelector(selectAuth);

    React.useEffect(() => {
        const header = headerRef.current;
        const sidebar = sidebarRef.current;
        const mainContainer = mainContainerRef.current;

        if (mainContainer && header) {
            const headerHeight = header.getBoundingClientRect().height;
            mainContainerRef.current.style.top = `${headerHeight}px`;
        }

        if (header && sidebar) {
            const headerHeight = header.getBoundingClientRect().height;

            sidebar.style.height = isGreaterThan("md")
                ? `calc(100vh - ${headerHeight}px)`
                : "100vh";
        }
    }, [auth.token, windowSize, isGreaterThan]);

    return (
        <>
            <Header ref={headerRef} />
            <div
                className={`grid  relative ${auth.token ? "content" : ""}`}
                ref={mainContainerRef}
            >
                {auth.token ? (
                    <>
                        <SideNav ref={sidebarRef} />
                        <div className="mainBody">{props.children}</div>
                    </>
                ) : (
                    props.children
                )}
            </div>
            <RenderModal />
        </>
    );
}
