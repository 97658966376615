import Loader from "components/Loader";
import MySwal from "components/MySwal";
import { Button, Label, TextInput } from "flowbite-react";
import { useFormik } from "formik";
import useModal from "hooks/useModal";
import ModalWrapper from "layout/ModalWrapper";
import React from "react";
import { useSendOtpMutation } from "store/apis/otp";
import * as Yup from "yup";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

function Form() {
    const { openModal } = useModal();

    const [sendOtp, otpResult] = useSendOtpMutation();

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        onSubmit: async (values) => {
            try {
                const otpResult = await sendOtp({
                    email: values.email,
                    otpType: "RESET_PASSWORD",
                }).unwrap();

                openModal("InsertOtp", {
                    otpToken: otpResult?.data?.otpToken,
                    otpType: "RESET_PASSWORD",
                    email: values.email,
                });
            } catch (error: any) {
                MySwal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error?.data?.message,
                    confirmButtonColor: "#3085d6",
                    customClass: {
                        confirmButton: `bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                                w-full sm:w-auto
                                `,
                        actions: "flex justify-center gap-2",
                    },
                });
            }
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
        }),
    });

    return (
        <>
            <form
                className="flex flex-col gap-4"
                onSubmit={formik.handleSubmit}
            >
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="email1" value="Your email" />
                    </div>
                    <TextInput
                        id="email1"
                        type="email"
                        placeholder="Enter your email"
                        required={true}
                        {...formik.getFieldProps("email")}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </div>

                <Button className="mt-4" type="submit">
                    Submit
                </Button>
            </form>

            <Loader isLoading={otpResult.isLoading} />
        </>
    );
}

const EnterForgetPassMail = ({ isOpen, onClose }: Props) => {
    return (
        <ModalWrapper title="Forgot password" isOpen={isOpen} onClose={onClose}>
            <Form />
        </ModalWrapper>
    );
};

export default React.memo(EnterForgetPassMail);
