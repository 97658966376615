import EnterForgetPassMail from "./EnterForgetPassMail";
import InsertOtp from "./InsertOtp";
import ResetPassword from "./ResetPassword";
import OtpWarring from "./OtpWarring";
import DeleteResturant from "./DeleteResturant";
import UpdateStatus from "./UpdateStatus";
import ChangePassword from "./ChangePassword";
import ChangeName from "./ChangeName";
import LogOutWarring from "./LogoutWarning";
import AddAdmin from "./AddAdmin";
import DeleteAdminWarning from './DeleteAdminWarning';

const registeredModals = {
    EnterForgetPassMail,
    InsertOtp,
    ResetPassword,
    OtpWarring,
    DeleteResturant,
    UpdateStatus,
    ChangePassword,
    ChangeName,
    LogOutWarring,
    AddAdmin,
    DeleteAdminWarning
};

export type TCurrentModal = keyof typeof registeredModals;

export default registeredModals;
