import Loader from "components/Loader";
import MySwal from "components/MySwal";
import { Button, Label, Modal, Select, Textarea } from "flowbite-react";
import { useFormik } from "formik";
import useModal from "hooks/useModal";
import { useUpdateResturantStatusMutation } from "store/apis/resturant";
import { IRestuantsTable } from "typedef/interface";
import * as Yup from "yup";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

function Form() {
    const { onClose, currentModal } = useModal();

    const [updateRestaurantStatFunc, updateRestaurantStatResult] =
        useUpdateResturantStatusMutation();

    const props = currentModal?.props as IRestuantsTable;

    const formik = useFormik({
        initialValues: {
            type: props.status || "",
            message: props.message || "",
        },
        onSubmit: async (values) => {
            try {
                const res = await updateRestaurantStatFunc({
                    _id: props._id,
                    status: values.type,
                    message: values.message,
                }).unwrap();
                onClose();
                MySwal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Status has been updated successfully",
                    confirmButtonColor: "#3085d6",
                    customClass: {
                        confirmButton: `bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                                w-full sm:w-auto
                                `,
                        actions: "flex justify-center gap-2",
                    },
                });
            } catch (error: any) {
                MySwal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error?.data?.message,
                    confirmButtonColor: "#3085d6",
                    customClass: {
                        confirmButton: `bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                                w-full sm:w-auto
                                `,
                        actions: "flex justify-center gap-2",
                    },
                });
            }
        },
        validationSchema: Yup.object().shape({
            type: Yup.string().required("Required"),
            message: Yup.string().required("Required"),
        }),
    });

    return (
        <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="message" value="Message" />
                </div>
                <Textarea
                    id="message"
                    placeholder="Message"
                    required={true}
                    {...formik.getFieldProps("message")}
                    helperText={
                        formik.touched.message && formik.errors.message
                            ? formik.errors.message
                            : ""
                    }
                />
            </div>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="type" value="Select Status" />
                </div>
                <Select
                    id="type"
                    placeholder="Select Status"
                    required={true}
                    {...formik.getFieldProps("type")}
                    helperText={
                        formik.touched.type && formik.errors.type
                            ? formik.errors.type
                            : ""
                    }
                >
                    <option value="pending">
                        <span className="text-gray-400">Pending</span>
                    </option>
                    <option value="rejected">
                        <span className="text-green-400">Rejected</span>
                    </option>
                    <option value="approved">
                        <span className="text-red-400">Approved</span>
                    </option>
                </Select>
            </div>

            <Button className="mt-4" type="submit">
                Update
            </Button>
            <Loader isLoading={updateRestaurantStatResult.isLoading} />
        </form>
    );
}
export default function UpdateStatus({ isOpen, onClose }: Props) {
    return (
        <Modal show={isOpen} position="center" onClose={onClose}>
            <Modal.Header>Update Status</Modal.Header>
            <Modal.Body>
                <Form />
            </Modal.Body>
        </Modal>
    );
}
