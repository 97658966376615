import { Modal } from "flowbite-react";
import React from "react";

interface Props {
    isOpen?: boolean;
    onClose?: () => void;
    title?: string;
    children?: React.ReactNode;
}

export default function ModalWrapper({
    isOpen,
    onClose,
    title,
    children,
}: Props) {
    return (
        <Modal show={isOpen} position="center" onClose={onClose}>
            {title ? <Modal.Header>{title}</Modal.Header> : null}
            <Modal.Body>{children}</Modal.Body>
        </Modal>
    );
}
