import Loader from "components/Loader";
import MySwal from "components/MySwal";
import { Button, Checkbox, Label, Modal, TextInput } from "flowbite-react";
import { useFormik } from "formik";
import useModal from "hooks/useModal";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    useChangePasswordMutation,
    useUpdateProfileMutation,
} from "store/apis/auth";
import { selectAuth, updateName } from "store/features/auth";
import { changePasswordSchema } from "validators/resetPasswordSchema";
import * as Yup from "yup";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

function Form() {
    const { onClose } = useModal();
    const dispatch = useDispatch();
    const auth = useSelector(selectAuth);

    const [changeNameFunc, changeNameResult] = useUpdateProfileMutation();

    const formik = useFormik({
        initialValues: {
            name: auth?.user?.name ?? "",
        },
        onSubmit: async (values) => {
            try {
                const res = await changeNameFunc({
                    name: values.name,
                }).unwrap();
                // @ts-ignore
                dispatch(
                    updateName({
                        name: values.name,
                    })
                );

                onClose();

                MySwal.fire({
                    icon: "success",
                    title: "Success",
                    text:
                        res?.data?.message ||
                        "Password has been reset successfully",
                    confirmButtonColor: "#3085d6",
                    customClass: {
                        confirmButton: `bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                                w-full sm:w-auto
                                `,
                        actions: "flex justify-center gap-2",
                    },
                });
            } catch (error: any) {
                MySwal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error?.data?.message,
                    confirmButtonColor: "#3085d6",
                    customClass: {
                        confirmButton: `bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                                w-full sm:w-auto
                                `,
                        actions: "flex justify-center gap-2",
                    },
                });
            }
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Name is required"),
        }),
    });

    return (
        <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="name" value="Name" />
                </div>
                <TextInput
                    id="name"
                    placeholder="Enter New Name"
                    helperText={formik.errors.name ?? ""}
                    required={true}
                    {...formik.getFieldProps("name")}
                />
            </div>

            <Button className="mt-4" type="submit">
                Submit
            </Button>
            <Loader isLoading={changeNameResult.isLoading} />
        </form>
    );
}
export default function ChangeName({ isOpen, onClose }: Props) {
    return (
        <Modal show={isOpen} position="center" onClose={onClose}>
            <Modal.Header>Change Name</Modal.Header>
            <Modal.Body>
                <Form />
            </Modal.Body>
        </Modal>
    );
}
