import { apiSlice } from "..";

export const otpApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        sendOtp: builder.mutation({
            query: (body: {
                email: string;
                otpType: "REGISTER" | "RESET_PASSWORD";
            }) => ({
                url: "otp/send",
                method: "POST",
                body,
            }),
        }),
        verifyOtp: builder.mutation({
            query: (body) => ({
                url: "otp/verify",
                method: "POST",
                body,
            }),
        }),
    }),
});

export const {
    useSendOtpMutation,
    useVerifyOtpMutation
} = otpApiSlice;
