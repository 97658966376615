import * as Yup from "yup";

export const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .max(20, "Password must be at most 20 characters")
        .required("Required")
        .matches(
            /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!#$%&? "])[a-zA-Z0-9!#$%&?]{6,20}$/,
            "Password must contain at least 1 uppercase letter, 1 lowercase letter, and 1 digit, and 1 special character"
        ),
    confirmPassword: Yup.string()
        // @ts-ignore
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required"),
});

export const changePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .max(20, "Password must be at most 20 characters")
        .required("Required"),
    newPassword: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Required")
        .matches(
            /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!#$%&? "])[a-zA-Z0-9!#$%&?]{6,20}$/,
            "Password must contain at least 1 uppercase letter, 1 lowercase letter, and 1 digit, and 1 special character"
        ),
    confirmPassword: Yup.string()
        // @ts-ignore
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Required"),
});
