import useModal from "hooks/useModal";
import React from "react";
import { AiFillHome, AiFillSetting, AiFillShop } from "react-icons/ai";
import { FaUserFriends } from "react-icons/fa";
import { GoSignOut } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { selectAuth } from "store/features/auth";
import { selectIsSidebarOpen, toggleSidebar } from "store/features/ui";

interface Props {
    // props
}

const sidenavItems = [
  {
    title: "Dashboard",
    icon: (props: any) => <AiFillHome {...props} />,
    link: "/dashboard",
    role: ["user", "admin", "superadmin"],
  },
  {
    title: "Users",
    icon: (props: any) => <FaUserFriends {...props} />,
    link: "/admin/users",
    role: ["admin", "superadmin"],
  },
  {
    title: "Manage Admins",
    icon: (props: any) => <FaUserFriends {...props} />,
    link: "/admin/admins",
    role: ["superadmin", "admin"],
  },
  {
    title: "Restaurants",
    icon: (props: any) => <AiFillShop {...props} />,
    link: "/admin/all-restaurants",
    role: ["admin", "superadmin"],
  },
  {
    title: "Settings",
    icon: (props: any) => <AiFillSetting {...props} />,
    link: "/my-account",
    role: ["admin", "user", "superadmin"],
  },
];

function SideNav(props: Props, ref: React.RefObject<HTMLDivElement>) {
    const isSidebarOpen = useSelector(selectIsSidebarOpen);
    const dispatch = useDispatch();
    const { openModal } = useModal();

    const location = useLocation();

    const auth = useSelector(selectAuth);

    const renderSideNavItems = () => {
        return sidenavItems.map((item, index) => {
            if (item.role.includes(auth.user?.role!)) {
                const Icon = item.icon;
                return (
                    <Link
                        to={item.link}
                        key={item.title}
                        className={`flex p-4 hover:bg-blue-300/50 items-center gap-4 border-b-1 border-[#ccc]
                        last:border-b-0 w-full justify-start
                         ${
                             location.pathname === item.link
                                 ? "bg-blue-300/50"
                                 : ""
                         }
                        `}
                    >
                        <Icon className="inline-block" />
                        <span>{item.title}</span>
                    </Link>
                );
            }
        });
    };

    return (
        <>
            <div className={`sidebar ${isSidebarOpen ? "open" : ""}`} ref={ref}>
                <div className="">
                    {renderSideNavItems()}
                    <button
                        className="flex p-4 hover:bg-blue-300/50 items-center gap-4 border-b-1 border-[#ccc] last:border-b-0 w-full justify-start"
                        onClick={() => openModal("LogOutWarring")}
                    >
                        <GoSignOut className="inline-block" />
                        <span>Sign Out</span>
                    </button>
                </div>
            </div>
            <div
                className={`backdrop ${isSidebarOpen ? "open" : ""}`}
                onClick={() => dispatch(toggleSidebar())}
            ></div>
        </>
    );
}

export default React.forwardRef(SideNav as any);
